<template>
  <div class="commonReport">
    <div class="bgf0 p10">
      <div class="p10 bgff mainBox">
        <el-tabs v-model="activeName" type="card" class="bgff">
          <el-tab-pane label="alg_cc_pkgs_conf" name="alg_cc_pkgs_conf">
            <CcPkgConfig></CcPkgConfig>
          </el-tab-pane>
          <el-tab-pane label="active Days" name="activeDays">
            <ActiveDays></ActiveDays>
          </el-tab-pane>
          <el-tab-pane label="adx White List" name="adxWhiteList">
            <AdxWhiteList></AdxWhiteList>
          </el-tab-pane>
          <el-tab-pane label="adx Black List" name="adxBlackList">
            <AdxBlackList></AdxBlackList>
          </el-tab-pane>
          <el-tab-pane label="app Black List" name="appBlackList">
            <AppBlackList></AppBlackList>
          </el-tab-pane>
          <el-tab-pane label="devices num" name="maxRecord">
            <MaxRecord></MaxRecord>
          </el-tab-pane>
          <el-tab-pane label="S1 Important" name="algPkg">
            <AlgPkg></AlgPkg>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
  import CcPkgConfig from './alg/ccPkgConfig.vue';
  import ActiveDays from './alg/activeDays.vue';
  import AdxWhiteList from './alg/adxWhiteList.vue';
  import AdxBlackList from '@/views/setting/alg/adxBlackList.vue';
  import AppBlackList from '@/views/setting/alg/appBlackList.vue';
  import MaxRecord from '@/views/setting/alg/maxRecord.vue';
  import AlgPkg from '@/views/setting/alg/algPkg.vue';

  export default {
    components: {
      CcPkgConfig,
      ActiveDays,
      AdxWhiteList,
      AdxBlackList,
      AppBlackList,
      MaxRecord,
      AlgPkg,
    },
    data() {
      return {
        activeName: 'alg_cc_pkgs_conf',
      };
    },
  };
</script>
<style lang="scss" scoped>
  .can_checked {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .checked_style {
      width: 30px;
      margin-right: 0;
      padding-left: 15px;
      box-sizing: border-box;
    }
    .w100 {
      width: calc(100% - 30px) !important;
    }
  }
  .flex_start {
    justify-content: flex-start;
  }
  .el-form-item {
    height: 40px;
  }
  .to_link {
    cursor: pointer;
    color: #409eff;
  }

  @media screen and (min-width: 993px) {
    .form_width {
      ::v-deep .el-form-item {
        height: 30px;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .form_width {
      ::v-deep .el-form-item {
        height: inherit;
      }
    }
  }
  @media screen and (max-width: 520px) {
    .mainBox {
      ::v-deep .form_width {
        .el-form-item__content,
        .el-form-item__label {
          padding-bottom: 0 !important;
        }
      }
    }
  }
</style>
